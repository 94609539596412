import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { MdErrorOutline } from 'react-icons/md';
import Modal from '../../../../components/Modal/Modal';
import { ButtonContainer, SmallerModal } from '../../styles/GerenciarClientes.styles';
import Button from '../../../../components/Button/Button';
import { blockUserFetchAction, logOutUserFetchAction } from '../../../../store/fetchActions/blockUserActions';
import { unlockUserFetchAction } from '../../../../store/fetchActions/unlockUserActions';
import { useTheme } from 'styled-components';
import TextField from '../../../../components/TextField';

const BlockUser = ({ openModal, closeModal, blockUser, CPFUSUARIO, optionText }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { usersData } = useSelector((state) => state?.user);

  const blockOrUnlockUser = ({ justificativa }) => {
    if (optionText === 'deslogar') {
      const paramLogout = { MOTIVO: justificativa, CPFUSUARIO: usersData?.CPFCNPJ, ID_PRODUTO_DIGITAL: usersData?.ID_PRODUTO_DIGITAL };
      dispatch(logOutUserFetchAction(paramLogout, closeModal));
    } else if (blockUser) {
      const paramBlock = {
        MOTIVO: justificativa,
        CPFUSUARIO: usersData?.CPFCNPJ,
        ID_PRODUTO_DIGITAL: usersData?.ID_PRODUTO_DIGITAL
      };
      dispatch(blockUserFetchAction(paramBlock, closeModal));
    } else {
      const paramUnlock = { MOTIVO: justificativa, CPFUSUARIO: CPFUSUARIO, ID_PRODUTO_DIGITAL: usersData?.ID_PRODUTO_DIGITAL };
      dispatch(unlockUserFetchAction(paramUnlock, closeModal));
    }

    reset({
      justificativa: '',
    });
  };

  const { handleSubmit: handleBlockUser, control: controlBlockUser, reset } = useForm({});

  return (
    <Modal open={openModal} close={closeModal}>
      <SmallerModal>
        <MdErrorOutline color={theme.colors.nutBrown} size={'6.944vw'} />
        <h3>{optionText ? 'Deslogar Cliente' : blockUser ? 'Bloquear cliente' : 'Desbloquear cliente'}</h3>
        <h5>Tem certeza que deseja {optionText ? optionText : blockUser ? 'bloquear' : 'desbloquear'} o cliente?</h5>
        <h4>Justificativa</h4>
        <form onSubmit={handleBlockUser(blockOrUnlockUser)}>
          <TextField
            variant={'outlined'}
            name={'justificativa'}
            width={'28.342vw'}
            multiline={true}
            minRows={3}
            required={true}
            size="large"
            control={controlBlockUser}
            inputProps={{ maxLength: 100 }}
          />
          <ButtonContainer>
            <Button
              backgroundcolor={theme.colors.buttonBack}
              width={'13.792vw'}
              height={'3.125vw'}
              hoverbgcolor={theme.colors.buttonBackHover}
              hovercolor={theme.colors.buttonBackHoverText}
              mycolor={theme.colors.buttonBackText}
              onClick={closeModal}
            >
              <span>Voltar</span>
            </Button>
            <Button
              backgroundcolor={theme.colors.buttonNegaded}
              width={'13.792vw'}
              height={'3.125vw'}
              hoverbgcolor={blockUser ? theme.colors.buttonNegadedHover : theme.colors.veryDarkCyan}
              hovercolor={theme.colors.buttonNegadedHoverText}
              mycolor={theme.colors.buttonNegadedText}
              type={'submit'}
            >
              <span>{optionText ? 'Deslogar' : blockUser ? 'Bloquear' : 'Desbloquear'}</span>
            </Button>
          </ButtonContainer>
        </form>
      </SmallerModal>
    </Modal>
  );
};

export default BlockUser;
