import { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  editRepresentativesActions,
  inativeRepresentativesActions,
} from '../../../../store/fetchActions/representativeActions';
import * as Styled from '../../styles/UsuariosAprovados.styles';
import * as S from './RepresentativesCard.styles';
import Accordion from '../../../../components/Accordion/index';
import formatCPFCNPJ from '../../../../utils/functions/formatCPFCNPJ';
import DropDown from '../../../../components/DropDown/DropDown';
import formatCEP from '../../../../utils/functions/formatCEP';
import ModalPassword from '../../../../components/ModalPassword/ModalPassword';
import Button from '../../../../components/Button/Button';
import { ImPlus } from 'react-icons/im';
import AddRepresentativeModal from './Components/AddRepresentativeModal';
import { useForm } from 'react-hook-form';
import DocumentRepresentative from '../Collums/DocumentRepresentative';
import { approveComiteAction } from '../../../../store/fetchActions/approveUserActions';
import SelectOptions from '../../../../components/SelectOptions';
import TextField from '../../../../components/TextField';
import { useTheme } from 'styled-components';
import {
  optionsClientsAprovedLegalRepresentation,
  optionsClientsAprovedActiveComite,
} from '../../../../utils/constant';
import ModalSendDocuments from '../../components/Modals/SendDocuments';

export default function LegalRepresentative() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState('panel1');
  const [isModalPasswordOpen, setIsModalPasswordOpen] = useState(false);
  const [nameSelectedItem, setNameSelectedItem] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingRepresentative, setIsEditingRepresentative] = useState('');
  const [functionSelectedItem, setFunctionSelectedItem] = useState(() => {});
  const [isAddRepresentativeModalOpen, setIsAddRepresentativeModalOpen] = useState(false);
  const [idDeactivate, setIdDeactivate] = useState();
  const [comiteActive, setComiteActive] = useState();
  const [representativeActive, setRepresentativeActive] = useState();
  const [executeDispatch, setExecuteDispatch] = useState('');
  const [documentTypeToSend, setDocumentTypeToSend] = useState('');
  const [isModalSendDocumentsOpen, setIsModalSendDocumentsOpen] = useState(false);

  const { handleSubmit, control, register } = useForm();

  const { listSignatories } = useSelector((state) => state.approveUser);
  const { legalRepresentativesData, usersData } = useSelector((state) => state.user);

  const arraySignatories = useMemo(
    () =>
      listSignatories?.map((val) => {
        return { ID: val.ID, NOME: val.DESCRICAO };
      }),
    [listSignatories],
  );

  const nameSignatories = (val) => {
    const listS = listSignatories?.filter((e) => e.ID === val);

    return listS?.length > 0 && listS[0]?.ID;
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setIsEditing(false);
    setIsEditingRepresentative('');
  };

  const reset = () => {
    setIsEditing(false);
    setIsEditingRepresentative('');
  };

  const openModalPassword = () => setIsModalPasswordOpen(true);
  const closeModalPassword = () => setIsModalPasswordOpen(false);

  const editRepresentative = (data, index) => {
    const param = data.REPRESENTANTES[index];
    const filterRepresentante = legalRepresentativesData?.filter(
      (e) => e.CPFCNPJ == data.REPRESENTANTES[index]?.CPFCNPJ?.replace(/[^a-z0-9]/gi, ''),
    );

    dispatch(
      editRepresentativesActions(
        {
          REPRESENTANTES: [
            {
              ID: filterRepresentante[0]?.ID,
              CNPJ_EMPRESA: usersData?.CPFCNPJ,
              ID_PRODUTO_DIGITAL: usersData?.ID_PRODUTO_DIGITAL,
              CPF: param?.CPFCNPJ.replace(/[^a-z0-9]/gi, ''),
              CELULAR: param?.TELEFONE,
              ENDERECO: param?.ENDERECO,
              NUMENDERECO: param?.NUMERO,
              COMPLEMENTO: param?.COMPLEMENTO,
              CEP: param?.CEP,
              BAIRRO: param?.BAIRRO,
              CIDADE: param?.CIDADE,
              UF: param?.UF,
              EMAIL: param?.EMAIL,
              DATA_NASCIMENTO: param?.NASCIMENTO,
              PRINCIPAL: 'N',
              NOME: param?.NOME,
              SIGNATARIO: param?.SIGNATARIO,
            },
          ],
        },
        (res) => {
          reset();
        },
      ),
    );
  };

  const callFunction = () => {
    const callFunction = {
      editData: () => setIsEditing(true),
      deactivate: () => {
        setExecuteDispatch('deactivate');
      },
      comite: () => {
        setExecuteDispatch('comite');
      },

      sendImages: () => {
        setDocumentTypeToSend('image/*');
        setIsModalSendDocumentsOpen(true);
      },
      sendDocuments: () => {
        setDocumentTypeToSend('.pdf');
        setIsModalSendDocumentsOpen(true);
      },
    };

    setFunctionSelectedItem(callFunction[nameSelectedItem]);
  };

  useEffect(() => {
    executeDispatch === 'comite' &&
      dispatch(
        approveComiteAction(
          {
            representantes: [
              {
                CPFCNPJ_USUARIO: comiteActive.CPFCNPJ_USUARIO,
                CPFCNPJ_EMPRESA: comiteActive.CPFCNPJ_EMPRESA,
                ID_PRODUTO_DIGITAL: usersData?.ID_PRODUTO_DIGITAL,
                EMAIL: comiteActive.EMAIL,
              },
            ],
          },
          'comite',
        ),
      );

    executeDispatch === 'deactivate' &&
      dispatch(inativeRepresentativesActions({ ID_REPRESENTANTE: idDeactivate }, { CPFCNPJ: usersData?.CPFCNPJ, ID_PRODUTO_DIGITAL: usersData?.ID_PRODUTO_DIGITAL }));
  }, [executeDispatch]);

  return (
    <>
      <Styled.RepresentativesHeader>
        {legalRepresentativesData?.length > 0 && <h4>Detalhes Representantes</h4>}
        <Button
          backgroundcolor={theme.colors.buttonConfirm}
          width="29.153vw"
          hoverbgcolor={theme.colors.buttonConfirmHover}
          hovercolor={theme.colors.buttonConfirmHoverText}
          mycolor={theme.colors.buttonConfirmText}
          type="submit"
          margin={'0 0 0 0'}
          onClick={() => {
            setIsAddRepresentativeModalOpen(true);
          }}
        >
          <ImPlus className="default-svg" size={'1.1vw'} />
          <span>ADICIONAR REPRESENTANTE</span>
        </Button>
      </Styled.RepresentativesHeader>
      {legalRepresentativesData?.map((representative, index) => {
        return (
          <S.ContainerAccordion key={index + '-AccordionsRepresentantes'}>
            <form onSubmit={handleSubmit((data) => editRepresentative(data, index))}>
              <Accordion
                expanded={expanded}
                handleChange={handleChange}
                mycolor={theme.colors.black}
                backcolor={theme.colors.veryLightGray_2}
                open={'panel' + (index + 1)}
                title={
                  <S.TitleAccordion>
                    <h2>{'Representante ' + (index + 1)}</h2>
                  </S.TitleAccordion>
                }
              >
                <S.Section>
                  <Styled.InformationContainerRepresentative>
                    <Styled.Row>
                      <TextField
                        control={control}
                        label={'Nome'}
                        disabled={true}
                        width={'29.306vw'}
                        name={'NOME'}
                        value={representative.NOME}
                        {...register(`REPRESENTANTES.${index}.NOME`, { value: representative.NOME })}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        control={control}
                        label={'CPF'}
                        disabled={true}
                        width={'12.958vw'}
                        name={'CPF_CNPJ' + index}
                        value={formatCPFCNPJ(representative.CPFCNPJ)}
                        {...register(`REPRESENTANTES.${index}.CPFCNPJ`, {
                          value: formatCPFCNPJ(representative.CPFCNPJ),
                        })}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        control={control}
                        label={'Nascimento'}
                        disabled={true}
                        width={'11.958vw'}
                        type={'date'}
                        name={'NASCIMENTO'}
                        value={representative.NASCIMENTO}
                        {...register(`REPRESENTANTES.${index}.NASCIMENTO`, { value: representative.NASCIMENTO })}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Styled.Row>
                    <Styled.Row>
                      <TextField
                        control={control}
                        label={'Nome da mãe'}
                        value={representative?.NOME_MAE}
                        disabled={true}
                        width={'29.306vw'}
                        name={'NOME_MAE' + index}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        control={control}
                        label={'E-mail'}
                        value={representative.EMAIL}
                        disabled={!(isEditing && isEditingRepresentative?.INDEX === index)}
                        width={'26.306vw'}
                        type={'email'}
                        name={'EMAIL'}
                        {...register(`REPRESENTANTES.${index}.EMAIL`, { value: representative.EMAIL })}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        control={control}
                        label={'Telefone'}
                        value={representative.CELULAR}
                        disabled={!(isEditing && isEditingRepresentative?.INDEX === index)}
                        width={'12.958vw'}
                        name={'TELEFONE'}
                        {...register(`REPRESENTANTES.${index}.TELEFONE`, { value: representative.CELULAR })}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Styled.Row>
                    <Styled.Row style={{ alignItems: 'flex-end' }}>
                      <TextField
                        control={control}
                        label={'Tipo do representante'}
                        value={representative.TIPO_REPRESENTANTE}
                        disabled={true}
                        width={'29.306vw'}
                        name={'TIPO_REPRESENTANTE' + index}
                        InputLabelProps={{ shrink: true }}
                      />

                      <SelectOptions
                        label={'Signatário'}
                        control={control}
                        options={arraySignatories}
                        name={'SIGNATARIO' + index}
                        width="21.306vw"
                        initialValue={nameSignatories(representative?.SIGNATARIO)}
                        disabled={!(isEditing && isEditingRepresentative?.INDEX === index)}
                        {...register(`REPRESENTANTES.${index}.SIGNATARIO`, { value: representative.SIGNATARIO })}
                        InputLabelProps={{ shrink: true }}
                      />
                      {representative?.DESCRICAO_STATUS && (
                        <TextField
                          control={control}
                          label={'Status do comite'}
                          value={representative?.DESCRICAO_STATUS}
                          disabled={true}
                          width={'14.306vw'}
                          name={'STATUS_COMITE' + index}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    </Styled.Row>
                    <Styled.EditButtonContainer>
                      <h4>Endereço</h4>
                    </Styled.EditButtonContainer>
                    <Styled.Row>
                      <TextField
                        control={control}
                        label={'CEP'}
                        disabled={!(isEditing && isEditingRepresentative?.INDEX === index)}
                        width={'13.958vw'}
                        name={'CEP'}
                        value={formatCEP(representative.CEP)}
                        multiline={true}
                        {...register(`REPRESENTANTES.${index}.CEP`, { value: representative.CEP })}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        control={control}
                        label={'Logradouro'}
                        disabled={!(isEditing && isEditingRepresentative?.INDEX === index)}
                        width={'25.958vw'}
                        name={'ENDERECO' + index}
                        value={representative.ENDERECO}
                        {...register(`REPRESENTANTES.${index}.ENDERECO`, { value: representative.ENDERECO })}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        control={control}
                        label={'Número'}
                        disabled={!(isEditing && isEditingRepresentative?.INDEX === index)}
                        width={'5.958vw'}
                        name={'NUMERO'}
                        value={representative.NUMENDERECO}
                        {...register(`REPRESENTANTES.${index}.NUMERO`, { value: representative.NUMENDERECO })}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Styled.Row>
                    <Styled.Row>
                      <TextField
                        control={control}
                        label={'Complemento'}
                        value={representative.COMPLEMENTO}
                        disabled={!(isEditing && isEditingRepresentative?.INDEX === index)}
                        width={'20.306vw'}
                        name={'COMPLEMENTO'}
                        required={false}
                        {...register(`REPRESENTANTES.${index}.COMPLEMENTO`, { value: representative?.COMPLEMENTO })}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        control={control}
                        label={'Bairro'}
                        value={representative.BAIRRO}
                        disabled={!(isEditing && isEditingRepresentative?.INDEX === index)}
                        width={'20.306vw'}
                        name={'BAIRRO'}
                        {...register(`REPRESENTANTES.${index}.BAIRRO`, { value: representative?.BAIRRO })}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        control={control}
                        label={'Cidade'}
                        value={representative.CIDADE}
                        disabled={!(isEditing && isEditingRepresentative?.INDEX === index)}
                        width={'15.306vw'}
                        name={'CIDADE'}
                        {...register(`REPRESENTANTES.${index}.CIDADE`, { value: representative.CIDADE })}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        control={control}
                        label={'UF'}
                        value={representative.UF}
                        disabled={!(isEditing && isEditingRepresentative?.INDEX === index)}
                        width={'4.306vw'}
                        name={'UF'}
                        inputProps={{ maxLength: 2 }}
                        {...register(`REPRESENTANTES.${index}.UF`, { value: representative.UF })}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Styled.Row>

                    {representative.DOCUMENTOS?.length > 0 && (
                      <S.titleDocs>
                        <DocumentRepresentative document={representative.DOCUMENTOS} />
                      </S.titleDocs>
                    )}

                    <Styled.ButtonContainer>
                      {isEditing && isEditingRepresentative?.INDEX === index && (
                        <>
                          <Button
                            backgroundcolor={theme.colors.buttonBack}
                            width={'12.153vw'}
                            height={'3.333vw'}
                            hoverbgcolor={theme.colors.buttonBackHover}
                            hovercolor={theme.colors.buttonBackHoverText}
                            mycolor={theme.colors.buttonBackText}
                            onClick={() => {
                              setIsEditing(false);
                              setIsEditingRepresentative('');
                            }}
                          >
                            <span>CANCELAR</span>
                          </Button>
                          <Button
                            backgroundcolor={theme.colors.buttonConfirm}
                            width={'12.153vw'}
                            height={'3.333vw'}
                            hoverbgcolor={theme.colors.buttonConfirmHover}
                            hovercolor={theme.colors.buttonConfirmHoverText}
                            mycolor={theme.colors.buttonConfirmText}
                            type="submit"
                          >
                            <span>SALVAR</span>
                          </Button>
                        </>
                      )}
                    </Styled.ButtonContainer>
                  </Styled.InformationContainerRepresentative>
                  <Styled.ManagementContainer>
                    <DropDown
                      isEditing={isEditing}
                      openModalPassword={(e) => openModalPassword()}
                      options={
                        representative.DESCRICAO_STATUS == 'AGUARDANDO ADMIN' ||
                        representative.DESCRICAO_STATUS == 'REPROVADO ADMIN'
                          ? optionsClientsAprovedActiveComite
                          : optionsClientsAprovedLegalRepresentation
                      }
                      setNameSelectedItem={(e) => {
                        setNameSelectedItem(e);
                        e === 'editData' && setIsEditingRepresentative({ INDEX: index, EDIT: true });
                        e === 'deactivate' && setIdDeactivate(representative.ID);
                        (e === 'sendImages' || e === 'sendDocuments') && 
                        setRepresentativeActive({
                          CPFCNPJ_USUARIO: representative.CPFCNPJ,
                          CPFCNPJ_EMPRESA: representative.CPFCNPJ_EMPRESA,
                          TIPO_REPRESENTANTE: representative.CODTIPOREPRESENTANTE,
                          ID_PRODUTO_DIGITAL: usersData?.ID_PRODUTO_DIGITAL,
                        });
                        e === 'comite' &&
                          setComiteActive({
                            CPFCNPJ_USUARIO: representative.CPFCNPJ,
                            CPFCNPJ_EMPRESA: representative.CPFCNPJ_EMPRESA,
                            EMAIL: representative.EMAIL,
                          });
                      }}
                    />
                  </Styled.ManagementContainer>
                </S.Section>
              </Accordion>
            </form>
          </S.ContainerAccordion>
        );
      })}
      {isModalPasswordOpen && (
        <ModalPassword openModal={isModalPasswordOpen} closeModal={closeModalPassword} nextFunction={callFunction} />
      )}

      {isModalSendDocumentsOpen && (
        <ModalSendDocuments
          openModal={isModalSendDocumentsOpen}
          closeModal={() => setIsModalSendDocumentsOpen(false)}
          accept={documentTypeToSend}
          conta={representativeActive}
        />
      )}

      {isAddRepresentativeModalOpen && (
        <AddRepresentativeModal
          openModal={isAddRepresentativeModalOpen}
          closeModal={() => setIsAddRepresentativeModalOpen(false)}
        />
      )}
    </>
  );
}
