import api from '../../utils/services/api';

import {
  userActionError,
  userActionLoading,
  userActionSuccess,
  userBalanceActionError,
  userBalanceActionLoading,
  userBalanceActionSuccess,
} from '../ducks/user';
import { hideAlert, showAlert } from '../ducks/sweetAlert';

export const userFetchAction = (info, willBeLoaded, callback = () => undefined) => {
  return async (dispatch) => {
    if (willBeLoaded === 'willBeLoaded') {
      dispatch(userActionLoading());
    }
    try {
      const response = await api.get(`usuario/listar?CPFCNPJ_CLIENTE=${info?.CPFCNPJ}&ID_PRODUTO_DIGITAL=${info?.ID_PRODUTO_DIGITAL}`);
      dispatch(userActionSuccess(response.data));
      callback();
    } catch (error) {
      dispatch(userActionError(error?.response?.data));
      callback();
    }
  };
};

export const userBalanceFetchAction = (params, filter) => {
  return async (dispatch) => {
    dispatch(userBalanceActionLoading());
    try {
      const response = await api.post(`usuario/historico/saldo`, params);
      dispatch(userBalanceActionSuccess(response.data));
    } catch (error) {
      dispatch(userBalanceActionError(error?.response?.data));
    }
  };
};

export const removeLegalRepresentative = (ID_REPRESENTANTE, CPFCNPJ_CLIENTE, callback, willBeLoaded) => {
  const params = {
    ID_REPRESENTANTE,
  };

  return async (dispatch) => {
    const closeModals = () => {
      callback();
    };

    dispatch(
      showAlert({
        title: 'Loading',
        text: '',
        icon: '',
        loadingSpinner: true,
      }),
    );

    try {
      const response = await api.post(`admin/usuario/representante/desativar`, params);

      dispatch(hideAlert());
      dispatch(userFetchAction({ CPFCNPJ: CPFCNPJ_CLIENTE, ID_PRODUTO_DIGITAL: null }, willBeLoaded));
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: response?.data?.mensagem,
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};

export const editLegalRepresentative = (data, CPFCNPJ_CLIENTE, callback, willBeLoaded) => {
  return async (dispatch) => {
    const closeModals = () => {
      callback();
    };

    dispatch(
      showAlert({
        title: 'Loading',
        text: '',
        icon: '',
        loadingSpinner: true,
      }),
    );

    try {
      const response = await api.post(`usuario/representante/atualizar`, data);

      dispatch(hideAlert());
      dispatch(userFetchAction({ CPFCNPJ: CPFCNPJ_CLIENTE, ID_PRODUTO_DIGITAL: null }, willBeLoaded));
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: response?.data?.mensagem,
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};

export const buscaCep = (cep, callback = () => null) => {
  const CEP = cep;

  return async (dispatch) => {
    dispatch(userBalanceActionLoading());
    try {
      const response = await api.get(`cep/buscar?CEP=` + CEP);
      dispatch(userBalanceActionSuccess(response.data));
      callback(response);
    } catch (error) {
      dispatch(userBalanceActionError(error?.response?.data));
    }
  };
};
