import axios from 'axios';
import { useEffect, useState } from 'react';
import Modal from '../../../../components/Modal/Modal';
import InformationColum from './components/InformationColum';
import InformationRepresentative from './components/InformationRepresentative';
import DocumentCollum from '../Collums/DocumentCollum';
import DocumentRepresentative from '../Collums/DocumentRepresentative';
import { useForm } from 'react-hook-form';
import SelectOptions from '../../../../components/SelectOptions';
import { useDispatch, useSelector } from 'react-redux';
import { SectionTitle } from '../../styles/GerenciarClientes.styles';
import Button from '../../../../components/Button/Button';
import { CheckCircle, DeniCircle } from '../../../../assets/icon/index';
import Accordion from '../../../../components/Accordion/index';
import {
  approveUserFetchAction,
  approveComiteAction,
  negadeComiteAction,
} from '../../../../store/fetchActions/approveUserActions';
import { showAlert } from '../../../../store/ducks/sweetAlert';
import * as S from './ClientesPendentesPj.styles';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import verifyArray from '../../../../utils/verifyArray';
import { tipos_signatarios } from '../../../../utils/constant';
import { useTheme } from 'styled-components';
import Loading from '../../../../components/Loading';
import ModalPassword from '../../../../components/ModalPassword/ModalPassword';
import ModalEditarTaxas from '../../../PerfisUsuarios/Modals/Perfis/EditTaxes';
import { taxesFetchAction } from '../../../../store/fetchActions/taxesActions';
import api from '../../../../utils/services/api';

const DetalhesPendendes = ({ open, close, info, deny }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [hoverColorDeni, setHoverColorDeni] = useState(theme.colors.white);
  const [expanded, setExpanded] = useState('panel1');
  const [statusRep, setStatusRep] = useState([]);
  const [comite, setComite] = useState([]);
  const [negadeComite, setNegadeComite] = useState([]);
  const [radio, setRadio] = useState([]);
  const [negateRep, setNegateRep] = useState([]);
  const [idProfile, setIdProfile] = useState();
  const [idCloned, setIdCloned] = useState();
  const [modalTaxas, setModalTaxas] = useState(false);
  const [modalPasswordEditarTaxas, setModalPasswordEditarTaxas] = useState(false);
  

  const { handleSubmit: handleSubmitApproval, control: controlAproval } = useForm({});

  const { cobansData } = useSelector((state) => state.cobans);
  const perfisArray = cobansData?.filter((val) => val.CPFCNPJ === null);

  const { loadingUser } = useSelector((state) => state.user);

  const submitApprovation = (data) => (data.perfil ? approveUser(data) : approveError());

  const approveError = () => {
    dispatch(
      showAlert({
        title: 'Perfil não selecionado!',
        text: 'Selecione um perfil para aprovar o cliente',
        icon: 'error',
      }),
    );
  };


  const approveUser = (data) => {
    const param = { CPFUSUARIO: info?.CPFCNPJ, ID_COBAN: data?.perfil, REPRESENTANTES: negateRep, ID_PRODUTO_DIGITAL: info?.ID_PRODUTO_DIGITAL };

    dispatch(
      approveUserFetchAction(param, close, (res) => {
        res &&
          (verifyArray(negadeComite) &&
            negadeComite?.forEach((val) => {
              dispatch(negadeComiteAction({ ID_USUARIO: val.ID }));
            }),
          verifyArray(comite) && dispatch(approveComiteAction({ representantes: comite, ID_PRODUTO_DIGITAL: info?.ID_PRODUTO_DIGITAL }, true, close)));
      }),
    );
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const changeStatus = (val, indx, colorBack, colorText, status) => {
    statusRep[indx] = { val, colorBack, colorText, status };
    setStatusRep(statusRep);
  };

  const changeComite = (event, val, indx) => {
    if (event.target.value === 'true' && val?.SIGNATARIO !== tipos_signatarios[0].ID) {
      setComite([
        ...comite,
        {
          CPFCNPJ_USUARIO: val.CPFCNPJ,
          CPFCNPJ_EMPRESA: val.CPFCNPJ_EMPRESA,
          EMAIL: val.EMAIL,
        },
      ]),
        setRadio([...radio, { INDEX: indx, STATUS: event.target.value }]);
      verifyArray(negadeComite) && setNegadeComite(negadeComite?.filter((e) => e.CPFCNPJ_USUARIO !== val.CPFCNPJ));
    }

    if (event.target.value === 'false') {
      setNegadeComite([
        ...negadeComite,
        {
          CPFCNPJ_USUARIO: val.CPFCNPJ,
          CPFCNPJ_EMPRESA: val.CPFCNPJ_EMPRESA,
          EMAIL: val.EMAIL,
          ID: val['ID_USUARIO_COMITE '],
        },
      ]);

      verifyArray(comite) &&
        (setComite(comite?.filter((e) => e.CPFCNPJ_USUARIO !== val.CPFCNPJ)),
        setRadio(
          radio?.filter((e) => e.INDEX !== indx),
          { INDEX: indx, STATUS: false },
        ));
    }
  };

  const valRadioCheck = (val) => {
    const valCheck = radio?.filter((e) => e.INDEX === val);
    if (valCheck !== undefined) {
      return valCheck[0]?.STATUS ?? false;
    } else {
      return false;
    }
  };

  const reset = () => {
    setStatusRep([]);
    setComite([]);
    setRadio([]);
    setExpanded('panel1');
  };
  
  const cloneProfile = async () => {
    try {
       const params = {
         "ID_COBAN": idProfile, 
         "NOME": info.NOME,
       };
   
       const response = await api.post('/coban/perfil/clonar', params);
       setIdCloned(response.data.ID_COBAN);
       console.log(idCloned)
       setModalPasswordEditarTaxas(true)
       console.log(response.data.ID_COBAN);
    } catch (error) {
       console.error(error);
    }
   };
  
   console.log("info",info.NOME)

  const editTaxes = (id) => {
    dispatch(taxesFetchAction(idProfile));
    setModalTaxas(true);
  };

  useEffect(() => {
    reset();
  }, [open]);

  const resetNegateRep = (val) => {
    const restartNegate = negateRep?.filter((e) => e?.CPF_REPRESENTANTE !== val);
    setNegateRep(restartNegate);
  };
  
  useEffect(() => {
    const initialRadio = info?.REPRESENTANTES?.map((val, index) => {
      if (val.SIGNATARIO === '2' || val.SIGNATARIO === '3') {
        return { INDEX: index, STATUS: true };
      } else {
        return { INDEX: index, STATUS: false };
      }
    });

    const initialComite = info?.REPRESENTANTES?.filter((val) => val.SIGNATARIO === '2' || val.SIGNATARIO === '3')?.map(
      (val, index) => {
        return { CPFCNPJ_USUARIO: val.CPFCNPJ, CPFCNPJ_EMPRESA: val.CPFCNPJ_EMPRESA, EMAIL: val.EMAIL };
      },
    );

    setRadio(initialRadio);

    verifyArray(initialComite) && setComite(initialComite);
  }, [info?.REPRESENTANTES]);

  return (
    <Modal
      open={open}
      close={() => {
        close(), reset();
      }}
      title="Dados Cliente"
      width={'96.222vw'}
    >
      {loadingUser ? (
        <Loading />
      ) : (
        <form onSubmit={handleSubmitApproval(submitApprovation)}>
          <S.InfoContainer>
            <InformationColum title="Informações empresariais" data={info} />
            <DocumentCollum />
          </S.InfoContainer>

          <S.InsertContainer>
            <S.InputSelect>
              <SectionTitle>Perfil Associado</SectionTitle>
              <SelectOptions
                control={controlAproval}
                options={perfisArray}
                name={'perfil'}
                width="35.111vw"
                label={'Perfil'}
                onClick={(data) => setIdProfile(data.ID)}
                required
              />
            </S.InputSelect>
            <div style={{ marginTop: '2.5rem' }}>
                <Button
                  backgroundcolor={theme.colors.buttonNegaded}
                  width="15.111vw"
                  height="3.125vw"
                  hoverbgcolor={theme.colors.buttonNegaded}
                  hovercolor={theme.colors.buttonNegadedHoverText}
                  mycolor={theme.colors.buttonNegadedText}
                  onClick={() => cloneProfile()}
                  onMouseOver={() => setHoverColorDeni(theme.colors.white)}
                  onMouseOut={() => setHoverColorDeni(theme.colors.white)}
                  
                ><span>Clonar Perfil</span></Button>
           </div>
          </S.InsertContainer>

          <S.TitleRepresentative>
            <SectionTitle>Detalhes Representantes</SectionTitle>
          </S.TitleRepresentative>

          {info?.REPRESENTANTES?.map((val, indx) => (
            <S.ContainerAccordion key={indx + '-AccordionsRepresentantes'}>
              <Accordion
                expanded={expanded}
                handleChange={handleChange}
                mycolor={theme.colors.black}
                backcolor={theme.colors.white}
                open={'panel' + (indx + 1)}
                title={
                  <S.TitleAccordion>
                    <h2>{'Representante ' + (indx + 1)}</h2>
                    {statusRep[indx]?.val && (
                      <S.ContainerStatus background={statusRep[indx]?.colorBack} color={statusRep[indx]?.colorText}>
                        {statusRep[indx]?.status}
                      </S.ContainerStatus>
                    )}
                  </S.TitleAccordion>
                }
              >
                <S.SectionsBody>
                  <InformationRepresentative data={val} />
                  <S.SectionsButton>
                    <Button
                      backgroundcolor={theme.colors.buttonApproved}
                      width="15.111vw"
                      height="3.125vw"
                      hoverbgcolor={theme.colors.buttonApprovedHover}
                      hovercolor={theme.colors.buttonApprovedHoverText}
                      mycolor={theme.colors.buttonApprovedText}
                      onClick={() => {
                        setExpanded(false);
                        changeStatus(
                          true,
                          indx,
                          theme.colors.darklimeGreenTransparent,
                          theme.colors.darklimeGreen,
                          'APROVADO',
                        );
                        resetNegateRep(val?.CPFCNPJ);
                      }}
                      margin={'1vw 0 1.5vw 0 '}
                    >
                      <span>Aprovar</span>
                    </Button>
                    <Button
                      backgroundcolor={theme.colors.strongRed}
                      width="15.111vw"
                      height="3.125vw"
                      hoverbgcolor={theme.colors.strongRed}
                      hovercolor={theme.colors.white}
                      mycolor={theme.colors.white}
                      onClick={() => {
                        setExpanded(false);
                        changeStatus(true, indx, theme.colors.vividRed_2Transparent, theme.colors.vividRed_2, 'NEGADO');
                        setNegateRep([...negateRep, { CPF_REPRESENTANTE: val?.CPFCNPJ, STATUS: 'REPROVADO' }]);
                      }}
                    >
                      <span>Negar</span>
                    </Button>
                    <S.RadioContainer>
                      <S.RadioLabel className="rContainer">O signatário faz parte do comitê?</S.RadioLabel>
                      <RadioGroup
                        key={indx + 'radioComite'}
                        row
                        name="comite"
                        value={valRadioCheck(indx)}
                        onChange={(e) => changeComite(e, val, indx)}
                      >
                        <FormControlLabel value={true} control={<Radio />} label="SIM" />
                        <FormControlLabel value={false} control={<Radio />} label="NÂO" />
                      </RadioGroup>
                    </S.RadioContainer>
                  </S.SectionsButton>
                </S.SectionsBody>
                <DocumentRepresentative document={val.DOCUMENTOS} />
              </Accordion>
            </S.ContainerAccordion>
          ))}

          <S.Footer>
            <Button
              backgroundcolor={theme.colors.buttonNegaded}
              width="35.111vw"
              height="3.125vw"
              hoverbgcolor={theme.colors.buttonNegadedHover}
              hovercolor={theme.colors.buttonNegadedHoverText}
              mycolor={theme.colors.buttonNegadedText}
              onClick={deny}
              onMouseOver={() => setHoverColorDeni(theme.colors.white)}
              onMouseOut={() => setHoverColorDeni(theme.colors.white)}
            >
              <DeniCircle color={hoverColorDeni} />
              <span>Negar</span>
            </Button>
            <Button
              backgroundcolor={theme.colors.buttonApproved}
              width="35.111vw"
              height="3.125vw"
              hoverbgcolor={theme.colors.buttonApprovedHover}
              hovercolor={theme.colors.buttonApprovedHoverText}
              mycolor={theme.colors.buttonApprovedText}
              type="submit"
            >
              <CheckCircle />
              <span>Aprovar</span>
            </Button>
          </S.Footer>
        </form>
      )}
      {modalTaxas && <ModalEditarTaxas open={modalTaxas} profileName={info.NOME} close={() => setModalTaxas(false)} id={idCloned} />}
      <ModalPassword
        openModal={modalPasswordEditarTaxas}
        closeModal={() => setModalPasswordEditarTaxas(false)}
        nextFunction={editTaxes}
      />
    </Modal>
  );
};

export default DetalhesPendendes;
