import React from 'react';

function Icon(props) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true" 
      role="img" 
      font-size="20" 
      class="iconify iconify--mdi" 
      width="30" 
      height="30" 
      viewBox="0 0 24 24"
      style={{ cursor: 'pointer' }}
      onClick={(e) => props.onClick()}
    >
      <path 
        fill="currentColor" 
        d="m14 2l6 6v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h8m4 18V9h-5V4H6v16h12m-6-1l-4-4h2.5v-3h3v3H16l-4 4Z"
      ></path>
    </svg>
  );
}

export default Icon;
