import { useDispatch, useSelector } from 'react-redux';
import * as Styled from '../../styles/UsuariosAprovados.styles';
import formatCPFCNPJ from '../../../../utils/functions/formatCPFCNPJ';
import { useState, useEffect, useMemo } from 'react';
import Button from '../../../../components/Button/Button';
import { profilesUsersFetchAction } from '../../../../store/fetchActions/perfisActions';
import { useForm } from 'react-hook-form';
import { editUserFetchAction } from '../../../../store/fetchActions/editUsersActions';
import { userFetchAction } from '../../../../store/fetchActions/usersActions';
import AmericanDate from '../../../../utils/functions/formatAmericanDate';
import { useTheme } from 'styled-components';
import TextField from '../../../../components/TextField';
import SelectInput from '../../../../components/SelectOptions/SelectInput';
import InputMask from '../../../../components/InputMask';
import InputFormatValue from '../../../../components/InputFormatValue';

export default function AboutCollum({ isEditing, cancel }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [optionsPerfil, setOptionsPerfil] = useState();

  useEffect(() => {
    dispatch(profilesUsersFetchAction());
  }, []);

  const { usersData } = useSelector((state) => state.user);
  const [fone, setFone] = useState(usersData?.FONECEL);

  const { profilesUsers } = useSelector((res) => res.perfis);

  const perfis = profilesUsers?.filter((val) => val.NOME === usersData?.NOMECOBAN);
  const cpfLength = usersData?.CPFCNPJ?.length;

  useEffect(() => {
    perfis?.length > 0 && optionsPerfil === undefined && setOptionsPerfil(perfis[0]?.ID);
  }, [perfis]);

  const { handleSubmit, control, reset } = useForm({});

  const editUser = (data) => {
    const param = {
      CPFCNPJ_CLIENTE: data.CPF_CNPJ?.replace(/[^a-z0-9]/gi, ''),
      EMAIL: data.EMAIL,
      NASCIMENTO: data.NASCIMENTO,
      NOME: data.NOME,
      TELEFONE: fone,
      ID_COBAN: optionsPerfil,
    };

    dispatch(editUserFetchAction(param, 'approvedUsers'));
    cancel();
    dispatch(userFetchAction({CPFCNPJ: usersData?.CPFCNPJ, ID_PRODUTO_DIGITAL: usersData?.ID_PRODUTO_DIGITAL}, 'willBeLoaded', reset()));
  };

  const cancelReset = () => {
    setFone(usersData?.FONECEL);
    dispatch(userFetchAction({CPFCNPJ: usersData?.CPFCNPJ, ID_PRODUTO_DIGITAL: usersData?.ID_PRODUTO_DIGITAL}, 'willBeLoaded', reset()));
  };

  useMemo(() => setFone(usersData?.FONECEL), [usersData?.FONECEL]);

  return (
    <form onSubmit={handleSubmit(editUser)}>
      <Styled.InformationContainer>
        <Styled.Row>
          <TextField
            control={control}
            label={cpfLength === 11 ? 'Nome' : 'Razão Social'}
            disabled={!isEditing}
            width={'29.306vw'}
            name={'NOME'}
            value={usersData.NOME}
          />
          <TextField
            control={control}
            label={cpfLength === 11 ? 'CPF' : 'CNPJ'}
            disabled={true}
            width={'12.958vw'}
            name={'CPF_CNPJ'}
            value={formatCPFCNPJ(usersData.CPFCNPJ)}
          />
          <TextField
            control={control}
            label={cpfLength === 11 ? 'Nascimento' : 'Abertura'}
            disabled={!isEditing}
            width={'11.958vw'}
            type={'date'}
            name={'NASCIMENTO'}
            value={AmericanDate(usersData.DATA_FUND_NASC)}
          />
        </Styled.Row>
        <Styled.Row>
          <TextField
            control={control}
            label={'E-mail'}
            value={usersData?.EMAIL}
            disabled={!isEditing}
            width={'21.306vw'}
            type={'email'}
            name={'EMAIL'}
          />
          <InputMask
            label="Telefone"
            name={'TELEFONE'}
            width={'12.958vw'}
            control={control}
            mask={'(99) 99999-9999'}
            value={fone}
            disabled={!isEditing}
            onChange={(e) => setFone(e)}
          />
          {optionsPerfil !== undefined && (
            <SelectInput
              width={'11.958vw'}
              label={'Perfil'}
              control={control}
              options={profilesUsers}
              initialValue={optionsPerfil}
              onClick={(e) => {
                setOptionsPerfil(e?.ID);
              }}
              disabled={!isEditing}
              name={'PERFIL'}
            />
          )}
          {usersData?.CPFCNPJ.length === 14 && (
            <TextField
              width={'10vw'}
              label={'Validade'}
              value={usersData?.DATA_VALIDADE}
              disabled={true}
              type="date"
              name={'DATA_VALIDADE'}
              control={control}
            />
          )}
        </Styled.Row>

        {usersData?.CPFCNPJ.length === 14 ? (
          <>
            <Styled.Row>
              <TextField
                control={control}
                label={'Código e descrição da natureza jurídica'}
                value={usersData.JURIDICA}
                disabled={true}
                width={'21.306vw'}
                name={'CODIGO_JURIDICA'}
                multiline={true}
              />
              <TextField
                control={control}
                label={'Situação cadastral'}
                value={usersData.SIT_CADASTRO}
                disabled={true}
                width={'12.958vw'}
                name={'SITUACAO_CADASTRAL'}
              />
              <TextField
                control={control}
                label={'Porte'}
                value={usersData.PORTE}
                disabled={true}
                width={'23.4vw'}
                name={'PORTE'}
              />
            </Styled.Row>
            <Styled.Row>
              <TextField
                control={control}
                label={'Código e descrição da atividade econômica principal'}
                value={usersData.ATIVIDADE}
                disabled={true}
                width={'68.4vw'}
                name={'CODIGO_ATIVIDADE'}
              />
            </Styled.Row>
            <Styled.Row>
              <InputFormatValue
                control={control}
                label={'Faturamento'}
                value={usersData?.RENDA_FATURAMENTO}
                disabled={true}
                width={'21.306vw'}
                name={'FATURAMENTO'}
              />
            </Styled.Row>
          </>
        ) : (
          <Styled.Row>
            <InputFormatValue
              control={control}
              label={'Renda'}
              value={usersData?.RENDA_FATURAMENTO}
              disabled={true}
              width={'21.306vw'}
              name={'RENDA'}
            />
          </Styled.Row>
        )}

        <Styled.ButtonContainer>
          {isEditing && (
            <>
              <Button
                backgroundcolor={theme.colors.buttonBack}
                width={'12.153vw'}
                height={'3.333vw'}
                hoverbgcolor={theme.colors.buttonBackHover}
                hovercolor={theme.colors.buttonBackHoverText}
                mycolor={theme.colors.buttonBackText}
                onClick={() => {
                  cancel();
                  cancelReset();
                }}
              >
                <span>CANCELAR</span>
              </Button>
              <Button
                backgroundcolor={theme.colors.buttonConfirm}
                width={'12.153vw'}
                height={'3.333vw'}
                hoverbgcolor={theme.colors.buttonConfirmHover}
                hovercolor={theme.colors.buttonConfirmHoverText}
                mycolor={theme.colors.buttonConfirmText}
                type="submit"
              >
                <span>SALVAR</span>
              </Button>
            </>
          )}
        </Styled.ButtonContainer>
      </Styled.InformationContainer>
    </form>
  );
}
