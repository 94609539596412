import api from '../../utils/services/api';

import {
  documentTypesLoading,
  documentTypesSuccess,
  documentTypesError,
  sendingDocumentsLoading,
  sendingDocumentsSuccess,
  sendingDocumentsError,
} from '../ducks/sendingDocuments';
import { userFetchAction } from '../fetchActions/usersActions';
import { detailAccountsFetchAction } from '../fetchActions/accountActions';
import { hideAlert, showAlert } from '../ducks/sweetAlert';

export const getDocumentTypes = () => {
  return async (dispatch) => {
    dispatch(documentTypesLoading());
    try {
      const response = await api.get(`cliente/tipo_documentos`);
      dispatch(documentTypesSuccess(response.data));
    } catch (error) {
      dispatch(documentTypesError(error?.response?.data));
    }
  };
};

export const sendNewDocuments = (params, closeModal, id) => {
  return async (dispatch) => {
    dispatch(sendingDocumentsLoading());
    dispatch(showAlert({}));

    try {
      const response = await api.post(`coban/inserir/documento`, params);

      dispatch(sendingDocumentsSuccess());
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: response?.data?.mensagem,
          icon: 'success',
          func: closeModal,
        }),
      );

      dispatch(userFetchAction({ CPFCNPJ: params?.CPFCNPJ_USUARIO, ID_PRODUTO_DIGITAL: params?.ID_PRODUTO_DIGITAL }, "wont'BeLoaded"));
      dispatch(detailAccountsFetchAction(id));
    } catch (error) {
      dispatch(sendingDocumentsError());
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
          func: closeModal,
        }),
      );
    }
  };
};

export const sendNewDocumentsRepresentative = (params, closeModal) => {
  return async (dispatch) => {
    dispatch(sendingDocumentsLoading());
    dispatch(showAlert({}));

    try {
      const response = await api.post(`coban/inserir/documento`, params);

      dispatch(sendingDocumentsSuccess());
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: response?.data?.mensagem,
          icon: 'success',
          func: closeModal,
        }),
      );

      dispatch(userFetchAction({ CPFCNPJ: params?.CPFCNPJCLIENTE, ID_PRODUTO_DIGITAL: params?.ID_PRODUTO_DIGITAL }, "wont'BeLoaded"));
    } catch (error) {
      dispatch(sendingDocumentsError());
      dispatch(
        showAlert({
          title: 'Ops...',
          text: error?.response?.data?.mensagem,
          icon: 'error',
          func: closeModal,
        }),
      );
    }
  };
};
